import {nMonthsAgo} from "./utils/date";
import {DocumentGrouping, TimePeriodCode} from "./utils/type";

const {NODE_ENV} = process.env;

export const isDevelopment = NODE_ENV === "development";
export const API_URL = window.location.origin;

interface PerformanceCardTimePeriod {
  code: TimePeriodCode;
  type: "absolute" | "relative" | "both";
}

export const config = {
  general: {
    useMWR: false,
    useProfitAndLoss: true,
  },
  overviewPage: {
    showAdvisor: true,
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
    performanceCardTimePeriodCodes: [
      {code: "GIVEN", type: "relative"},
      {code: "GIVEN", type: "absolute"},
    ] as PerformanceCardTimePeriod[]
  },
  portfolioPage: {
    showKID: true,
    showCashBalance: false,
    performanceCardTimePeriodCodes: [
      {code: "GIVEN", type: "relative"},
      {code: "GIVEN", type: "absolute"},
    ] as PerformanceCardTimePeriod[],
    depositAndWithdrawal: {
      showDepositAndWithdrawal: false,
      depositTag: "deposit-waiting",
      withdrawalTag: "withdrawal-waiting",
    },
    defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
    timePeriodCodes: [
      "MONTHS-1",
      "MONTHS-3",
      "MONTHS-6",
      "CALYEAR-0",
      "YEARS-1",
      "YEARS-3",
      "GIVEN",
    ] as TimePeriodCode[],
    portfolioList: {
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "MONTHS-6",
        "CALYEAR-0",
        "YEARS-1",
        "GIVEN",
      ] as TimePeriodCode[],
      portfolioStatusesToToggle: ["P", "C"],
    },
  },
  costsPage: {
    showCostsPage: false,
    timePeriods: [
      {code: "MONTHS-1" as TimePeriodCode, startDate: nMonthsAgo(1)},
      {code: "MONTHS-3" as TimePeriodCode, startDate: nMonthsAgo(3)},
      {code: "MONTHS-6" as TimePeriodCode, startDate: nMonthsAgo(6)},
      {
        code: "CALYEAR-0" as TimePeriodCode,
        startDate: new Date(new Date().getFullYear(), 0, 1),
      },
    ],
  },
  components: {
    securityDetails: {
      defaultTimePeriodCode: "CALYEAR-0" as TimePeriodCode,
      timePeriodCodes: [
        "MONTHS-1",
        "MONTHS-3",
        "CALYEAR-0",
        "YEARS-1",
        "YEARS-3",
        "YEARS-5"
      ] as TimePeriodCode[],
    },
  },
  tradingPage: {
    showTradingPage: true,
    tradeableSecuritiesTags: ["AssetManager:AlfredBerg"]
  },
  savingsPage: {
    showSavingsPage: false,
    minSavingsPlanAmount: 1000,
    minInvestmentAmount: 1000,
    maxNumberOfSecurities: 10,
  },
  securityDetailsPage: {
    showSecurityCosts: false,
  },
  documentsPage: {
    documentGrouping: [
      {
        pattern: /\b(skatt\d{4}|tax)\b/i,
        label: "Skattedokumenter",
      }
    ] as DocumentGrouping[],
    reports: [
      {
        reportName: "DNB - Contract notes",
        label: "Sluttsedler"
      },
      {
        reportName: "DNB - Bankkontoutdrag",
        label: "Kontoutdrag"
      },
      {
        reportName: "DNB - Kostnadsoversikt",
        label: "Kostnadsoversikt"
      },
    ]
  }
};
